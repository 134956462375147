import '../styles/globals.less'
import type { AppProps } from 'next/app'
import { LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import Wrapper from 'store'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import jwt_decode from 'jwt-decode'

const LayoutDynamic = dynamic(() => import('components/layout'), {
  ssr: false,
  loading: () => (
    <BoxStyled>
      <LoadingOutlined style={{ fontSize: 30, color: '#5DA6E7' }} spin />
    </BoxStyled>
  ),
})

function MyApp({ Component, pageProps }: AppProps) {
  const authentication: any = useSelector<any>((state) => state.authentication)
  const router = useRouter()
  const { pathname, query } = router
  let token = ''
  let companyId = ''
  let oid = ''
  let email = ''
  if (typeof window !== 'undefined') {
    token = localStorage.getItem('token') || ''
    companyId = localStorage.getItem('company_id') || ''
  }

  useEffect(() => {
    switch (true) {
      case typeof query?.token === 'string' &&
           typeof query?.company_id === 'string' &&
           typeof query?.oid === 'string' &&
           typeof query?.email === 'string':
        token = query.token;
        companyId = query.company_id;
        oid = query.oid;
        email = query.email;
        let decodedToken: any = {}
        if (token) {
          decodedToken = jwt_decode(token)
        }
        localStorage.setItem('token', token);
        localStorage.setItem('company_id', companyId);
        localStorage.setItem('oid', oid);
        localStorage.setItem('email', email);
        localStorage.setItem('level', decodedToken?.account_roles?.roles?.level)
        router.push('/home');
        break;
  
      case token && router && (
        router.pathname === '/login' ||
        router.pathname === '/register' ||
        router.pathname === '/forget-password' ||
        router.pathname === '/'
      ):
        router.push('/home');
        break;
    }
    
  }, [query,router])
  

  return (
    <LayoutDynamic>
      <Component {...pageProps} />
    </LayoutDynamic>
  )
}

export default Wrapper.withRedux(MyApp)

const BoxStyled = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
